import {
    CustomerAccountContext,
    ICustomerAccount,
    ICustomerAccountContextInternalAPI,
} from './CustomerAccountContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../boutikService';
import { useGetUserProfileQuery } from './graphql';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { BoutikService } from '../../engine/BoutikService';

export interface ICustomerAccountProviderProps {
    children: React.ReactNode;
}

export const CustomerAccountProvider: React.FC<
    ICustomerAccountProviderProps
> = ({ children }) => {
    const apolloClient = useApolloClient();

    const { boutikService, isAuthenticated, assertNotAuthenticated } =
        useBoutikService();
    const [customerProfile, setCustomerProfile] =
        useLocalStorage<ICustomerAccount>('CustomerAccount:customerProfile');

    const { data: userProfileData, loading: userProfileLoading } =
        useGetUserProfileQuery({
            fetchPolicy: 'cache-first',
            skip: !isAuthenticated,
        });

    const [store, setStore] = useState<string | undefined>(undefined);
    const [language, setLanguage] = useState<string | undefined>(undefined);

    useGetMagentoCurrentCustomerData(boutikService, isAuthenticated).then(
        (data) => {
            if (data) {
                const createdInArray = data.created_in.split(' ');
                const country = createdInArray[2];
                const language = createdInArray[1].toLowerCase();

                setStore(country === 'US' ? country : 'CA');
                setLanguage(
                    language.includes('fr') ? `fr_${country}` : `en_${country}`
                );
            }
        }
    );

    useEffect(() => {
        if (userProfileData?.customer) {
            setCustomerProfile({
                email: userProfileData.customer.email || '',
                firstname: userProfileData.customer.firstname || '',
                lastname: userProfileData.customer.lastname || '',
                isSubscribed: userProfileData.customer.isSubscribed || false,
                store: store,
                lang: language,
            });
        }
    }, [userProfileData, setCustomerProfile, store, language]);

    const clearCustomerProfile = useCallback(() => {
        assertNotAuthenticated();
        setCustomerProfile(undefined);
        apolloClient.cache.reset();
    }, [assertNotAuthenticated, setCustomerProfile, apolloClient]);

    // Implicitely destroy customer profile and cache when user gets deauthenticated.
    // Note that clearCustomerProfile() is explicitely called in logout scenarios;
    // however, this effect is still required to handle the case where the user gets
    // deauthentified because his auth token expired.
    useEffect(() => {
        if (!isAuthenticated && customerProfile) clearCustomerProfile();
    }, [isAuthenticated, customerProfile, clearCustomerProfile]);

    const contextValue = useMemo<ICustomerAccountContextInternalAPI>(() => {
        return {
            currentUser: customerProfile,
            isIdentified: customerProfile !== undefined,
            clearCustomerProfile,
        };
    }, [customerProfile, clearCustomerProfile]);

    return (
        <CustomerAccountContext.Provider value={contextValue}>
            {children}
        </CustomerAccountContext.Provider>
    );
};

async function useGetMagentoCurrentCustomerData(
    boutikService: BoutikService,
    isAuthenticated: boolean
) {
    // BoutikService might not be ready yet or customer might have been deauthenticated.
    if (
        !boutikService._storeViewCode ||
        !boutikService._accessToken ||
        !isAuthenticated
    ) {
        return null;
    }

    const url = `/rest/${boutikService._storeViewCode}/V1/customers/me`;

    const response = await fetch(url, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${boutikService._accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        console.error(`Magento ${url} call failed`, response);
        throw new Error(`Magento ${url} call failed`);
    }

    const data = await response.json();

    return data;
}
