module.exports = [{
      plugin: require('../../gatsby-theme-boutik-ecommerce/gatsby-browser.js'),
      options: {"plugins":[],"magentoBackend":"https://magento-production.sbintl.net/","siteName":"occ","configPath":"/home/runner/work/boutik.occ/boutik.occ/packages/site-occ/src/i18n/config.json","injectSiteManifestDefinition":"auto","injectManifestPluginDefinition":"auto"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/home-icon.png","background_color":"#FFFFFF","theme_color":"#fd4239","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"lang":"fr-CA","start_url":"/ca/fr","name":"name","short_name":"short_name","description":"description","localize":[{"lang":"en-CA","start_url":"/ca/en","name":"name","short_name":"short_name","description":"description"},{"lang":"en-US","start_url":"/us/en","name":"name","short_name":"short_name","description":"description"}],"cacheDigest":"e6b0f0023378eb606c037297ca00d1b0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
