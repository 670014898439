import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client';
import { possibleTypes, typePolicies } from './apis/magento2';

import { RetryLink } from '@apollo/client/link/retry';
import { fetch } from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { stripIgnoredCharacters } from 'graphql/utilities/stripIgnoredCharacters';

// import MutationQueueLink from '@adobe/apollo-link-mutation-queue';
// import { get as getWithPath, set as setWithPath } from 'lodash';

export interface IBoutikServiceSpec {
    apiBase: string;
    storeViewCode: string;
}

export class BoutikService {
    private _serverHost: string;

    private _storeViewCode: string | undefined;
    public set storeViewCode(storeViewCode: string) {
        this._storeViewCode = storeViewCode;
    }

    private _apolloClient: ApolloClient<any>;
    public get apolloClient() {
        return this._apolloClient;
    }

    private _accessToken: string | undefined = undefined;
    public set accessToken(accessToken: string | undefined) {
        this._accessToken = accessToken;
    }

    constructor(apiServer: IBoutikServiceSpec) {
        this._serverHost = apiServer.apiBase;
        this._apolloClient = this.setupApolloClient();
    }

    // The following function as been largely inspired from the following files:
    //    - pwa-studio/venia-concept/src/index.js
    //    - pwa-studio/venia-ui/lib/drivers/adapter.js
    private setupApolloClient() {
        const cache = new InMemoryCache({
            typePolicies,
            possibleTypes,
        });

        const httpLink = createHttpLink({
            uri: this._serverHost + '/graphql',
            fetch: customFetchToShrinkQuery,
            useGETForQueries: false,
        });

        const authLink = setContext((_, { headers }) => {
            if (typeof this._storeViewCode === 'undefined')
                throw new Error('StoreViewCode has not yet been set.');

            return {
                headers: {
                    ...headers,
                    store: this._storeViewCode,
                    authorization: this._accessToken
                        ? `Bearer ${this._accessToken}`
                        : '',
                },
            };
        });

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                );

            if (networkError) console.log(`[Network error]: ${networkError}`);

            // if (response) {
            //     const { data, errors } = response;
            //     let pathToCartItems;

            //     // It's within the GraphQL spec to receive data and errors, where errors are merely informational and not
            //     // intended to block. Almost all existing components were not built with this in mind, so we build special
            //     // handling of this error message so we can deal with it at the time we deem appropriate.
            //     errors.forEach(({ message, path }, index) => {
            //         if (
            //             message ===
            //                 'Some of the products are out of stock.' ||
            //             message ===
            //                 'There are no source items with the in stock status'
            //         ) {
            //             if (!pathToCartItems) {
            //                 pathToCartItems = path.slice(0, -1);
            //             }

            //             // Set the error to null to be cleaned up later
            //             // response.errors[index] = null;
            //         }
            //     });

            //     // indicator that we have some cleanup to perform on the response
            //     if (pathToCartItems) {
            //         const cartItems = getWithPath(data, pathToCartItems);
            //         const filteredCartItems = cartItems.filter(
            //             (cartItem) => cartItem !== null
            //         );
            //         setWithPath(data, pathToCartItems, filteredCartItems);

            //         const filteredErrors = response.errors.filter(
            //             (error) => error !== null
            //         );
            //         // If all errors were stock related and set to null, reset the error response so it doesn't throw
            //         response.errors = filteredErrors.length
            //             ? filteredErrors
            //             : undefined;
            //     }
            // }
        });

        return new ApolloClient({
            cache,
            link: ApolloLink.from([
                // new MutationQueueLink(),
                new RetryLink({
                    delay: {
                        initial: 300,
                        max: Infinity,
                        jitter: true,
                    },
                    attempts: {
                        max: 5,
                        retryIf: (error) => {
                            return (
                                error &&
                                (typeof navigator === 'undefined' ||
                                    navigator.onLine)
                            );
                        },
                    },
                }),
                authLink,
                errorLink,
                httpLink,
            ]),
            connectToDevTools: true, // FIXME: process.env.NODE_ENV doesn't work... Why?
        });
    }
}

/**
 * Shrink a GraphQL query inside of a URL used in a GET request.
 * There are 2 problems with Apollo-client's encoding of URLs:
 *  1. Unnecessary spaces/line-breaks/punctuators are not removed, which leads to them being
 *     encoded as hex, increasing the URL length dramatically
 *  2. `encodeURI` is used, which encodes spaces with 3 characters (%20). Because the GraphQL query
 *     is inside of a querystring, we can use application/x-www-form-urlencoded, which encodes
 *     spaces with a single character
 *
 * @param {string | URL} Absolute URL for GraphQL GET query
 * @returns {string} Absolute URL, with shrunken query
 */
function shrinkGETQuery(fullURL: string) {
    const url = new URL(fullURL);

    // Read from URL implicitly decodes the querystring
    const query = url.searchParams.get('query');
    if (!query) {
        return fullURL;
    }

    const strippedQuery = stripIgnoredCharacters(query);

    // URLSearchParams.set will use application/x-www-form-urlencoded encoding
    url.searchParams.set('query', strippedQuery);

    return url.toString();
}

// Intercept and shrink URLs from GET queries. Using GET makes it possible to use edge caching in
// Magento Cloud, but risks exceeding URL limits with default usage of Apollo's http link.
// `shrinkGETQuery` encodes the URL in a more efficient way.
function customFetchToShrinkQuery(uri: string, options?: RequestInit) {
    let url = uri;
    if (options?.method === 'GET') {
        url = shrinkGETQuery(uri);
    }
    return fetch(url, options);
}
