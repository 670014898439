import { HttpResponse } from '../../models/httpResponse';

export enum AuthorizationStatus {
    IDLE = 0,
    ALLOWED = 1,
    DENIED = 2,
}

export type APIRestHeaders = {
    'Content-Type': string;
    Accept: string;
    Authorization?: string;
};

export interface HttpService {
    get: (url: string) => Promise<HttpResponse>;
    getBlob(url: string): Promise<Blob>;
}