import { useCallback, useMemo } from 'react';

import { useGetPasswordRequirementsQuery } from '../graphql';

export type PasswordRequirementValidationIssue =
    | // Password does not meet minimum length requirement
    'too-short'

    // Password does not meet the minium required character classes requirement
    | 'not-enough-classes'

    // Password requirements are not yet known. This is a temporary issue that
    // will resolve by itself
    | 'standby';

export interface IPasswordRequirementValidationResult {
    // Is the supplied password acceptable according to requirements
    acceptable: boolean;

    // Issues with the supplied password.
    issues: PasswordRequirementValidationIssue[];
}

export interface IPasswordRequirements {
    // Minimum length of the password, in characters
    minimumPasswordLength: number;

    // Minimum number of distinct character classes that must be present in
    // password. Possible characters classes are: lower case letters ([a-z]),
    // upper case letters ([A-Z]), digits ([0-9]) and others (anything else).
    requiredCharacterClassesNumber: number;
}

interface IPasswordRequirementsHookReturn {
    requirements: IPasswordRequirements;
    validate: (password: string) => IPasswordRequirementValidationResult;
}

export function usePasswordRequirements(): IPasswordRequirementsHookReturn {
    const { data } = useGetPasswordRequirementsQuery({});

    const minimumPasswordLength = parseInt(
        data?.storeConfig?.minimumPasswordLength || '0',
        10
    );
    const requiredCharacterClassesNumber = parseInt(
        data?.storeConfig?.requiredCharacterClassesNumber || '0',
        10
    );

    const requirements = useMemo(() => {
        return {
            minimumPasswordLength,
            requiredCharacterClassesNumber,
        };
    }, [minimumPasswordLength, requiredCharacterClassesNumber]);

    const validate = useCallback(
        (inputPassword: string): IPasswordRequirementValidationResult => {
            if (!data)
                return {
                    acceptable: false,
                    issues: ['standby'],
                };

            let actualCharacterClassesCount = 0;
            if (inputPassword.match(/[0-9]/)) actualCharacterClassesCount++;
            if (inputPassword.match(/[a-z]/)) actualCharacterClassesCount++;
            if (inputPassword.match(/[A-Z]/)) actualCharacterClassesCount++;
            if (inputPassword.match(/[^0-9a-zA-Z]/))
                actualCharacterClassesCount++;

            const issues: PasswordRequirementValidationIssue[] = [];

            if (
                minimumPasswordLength > 0 &&
                inputPassword.length < minimumPasswordLength
            )
                issues.push('too-short');

            if (
                requiredCharacterClassesNumber > 0 &&
                actualCharacterClassesCount < requiredCharacterClassesNumber
            )
                issues.push('not-enough-classes');

            return {
                acceptable: issues.length === 0,
                issues,
            };
        },
        [data, minimumPasswordLength, requiredCharacterClassesNumber]
    );

    return {
        requirements,
        validate,
    };
}
