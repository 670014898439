import { useGetOrderQuery, useGetOrdersQuery } from '../graphql';

import { useMemo } from 'react';

export const useCustomerOrders = () => {
    const ordersQuery = useGetOrdersQuery();

    const orders = ordersQuery.data?.customer?.orders?.items || [];
    return [orders, ordersQuery] as const;
};

export const useCustomerOrder = (orderNumber: string) => {
    const orderQuery = useGetOrderQuery({
        variables: { orderNumber: orderNumber },
    });

    const order = useMemo(() => {
        return orderQuery.data?.customer?.orders?.items?.[0];
    }, [orderQuery]);

    return [order, orderQuery] as const;
};
