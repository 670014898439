import {
    GlobalLocalizationProvider,
    PageLocalizationProvider,
} from '@sbi-international/boutik.gatsby.plugin.multilocale';
import { BoutikService } from './src/engine/BoutikService';
import { BoutikServiceProvider } from './src/context/boutikService';
import { CartProvider } from './src/context/cart';
import { OtherBoutikServicesProvider } from './src/context/otherBoutikServices/OtherBoutikServicesProvider';
import { CustomerAccountProvider } from './src/context/customerAccount';
import { FeedbackProvider } from './src/context/feedback/feedbackContext';
import React from 'react';
import { StoreProvider } from './src/context/store';
import { LocationProvider } from '@reach/router';
import { cookieConsentConfig } from './cookieConsentConfig';
import { CookiesConsentProvider } from '@sbi-international/boutik.package.cookie.consent';
import { ServicesContextProvider } from '@sbi-international/boutik.gatsby.plugin.services/dist/src/contexts/ServicesContextProvider';

export const onServiceWorkerUpdateReady = () => {
    /* TODO update only when new version

    if (answer === true) {*/
    window.location.reload();
    /*} */
};

export const wrapRootElement = ({ element }, { magentoBackend }) => {
    const apiBase =
        typeof window !== 'undefined' ? window.location?.origin : null;

    const boutikService = new BoutikService({
        apiBase: apiBase || magentoBackend,
    });

    return (
        <GlobalLocalizationProvider>
            <FeedbackProvider>
                <ServicesContextProvider>
                    <BoutikServiceProvider boutikService={boutikService}>
                        <OtherBoutikServicesProvider>
                            <CustomerAccountProvider>
                                <LocationProvider>
                                    <CookiesConsentProvider
                                        config={cookieConsentConfig}
                                    >
                                        {element}
                                    </CookiesConsentProvider>
                                </LocationProvider>
                            </CustomerAccountProvider>
                        </OtherBoutikServicesProvider>
                    </BoutikServiceProvider>
                </ServicesContextProvider>
            </FeedbackProvider>
        </GlobalLocalizationProvider>
    );
};

export const wrapPageElement = ({ element, props: { pageContext } }) => {
    return (
        <PageLocalizationProvider pageContext={pageContext}>
            <StoreProvider>
                <CartProvider>{element}</CartProvider>
            </StoreProvider>
        </PageLocalizationProvider>
    );
};
