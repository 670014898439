import React from 'react';

import { AxiosService } from '../../services/axiosService';

import { ReviewsServiceImpl } from '../../services/reviewsServiceImpl';
import { MagentoReviewsHttpRepository } from '../../repositories/magentoReviewsHttpRepository';
import { ImageServiceImpl } from '../../services/imageServiceImpl';
import { ImageHttpRepository } from '../../repositories/imageHttpRepository';

export type OtherBoutikService = any;

export type OtherBoutikServices = Record<string, OtherBoutikService>;

const initialize = () => {
    const services: OtherBoutikServices = {};

    services['ImageService'] = new ImageServiceImpl(
        new ImageHttpRepository(new AxiosService('', ' ' as string))
    );

    // le code store occ_fr_ca est utilisé, mais le enpoint va repérer tout les stores (ca en, ca fr et us)
    services['ReviewsService'] = new ReviewsServiceImpl(
        new MagentoReviewsHttpRepository(
            new AxiosService(
                `/rest/occ_fr_ca/V1`,
                process.env.GATSBY_MAGENTO_API_TOKEN as string
            )
        ),
        services['ImageService']
    );

    return services;
};

export const OtherBoutikServicesContext =
    React.createContext<OtherBoutikServices>(initialize());
